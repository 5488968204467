@import 'utility/pageBreak.css';

@media print{
    @page {
        size: A4 portrait;
        margin:1cm;
    }
    html, #scrollContainer { overflow: visible; }

    *[class*='shadow']{
        box-shadow:none !important;
    }

    #app {
        @apply bg-white;
    }

    body {
        filter: grayscale(100%);
    }

    section {
        /*page-break-inside: avoid;*/
    }

    * {
        @apply text-sm;
    }

    table, .table {
        page-break-inside: auto;
        /*page-break-inside: avoid;*/
    }

    .th, th {
        @apply bg-white text-black;
    }

    .form-input{
        @apply border-0 bg-transparent p-0 shadow-none overflow-visible;
    }

    .form-input:disabled{
        @apply bg-transparent;
    }

    .form-input-group {
        page-break-inside: avoid;
    }
}