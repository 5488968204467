@layer utilities {
    /* Page Break Inside */
    .break-inside-auto {
        page-break-inside: auto;
    }

    .break-inside-avoid {
        page-break-inside: avoid;
    }

    /* Page Break After */
    .break-after-auto {
        page-break-after: auto;
    }

    .break-after-avoid {
        page-break-after: avoid;
    }

    .break-after-always {
        page-break-after: always;
    }

    .break-after-left {
        page-break-after: left;
    }

    .break-after-right {
        page-break-after: right;
    }

    /* Page Break Before */
    .break-before-auto {
        page-break-before: auto;
    }

    .break-before-avoid {
        page-break-before: avoid;
    }

    .break-before-always {
        page-break-before: always;
    }

    .break-before-left {
        page-break-before: left;
    }

    .break-before-right {
        page-break-before: right;
    }
}
