@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'print.css';

@layer utilities {
    [v-cloak], [x-cloak] {
        display: none !important;
    }
    .order-last{
        order: theme('order.last');
        -webkit-order:theme('order.last');
    }
    .order-none{
        order: theme('order.none');
        -webkit-order:theme('order.none');
    }
}

@layer base {

    .part-image {
        @apply w-20 h-20 bg-white rounded overflow-hidden border flex items-center ring ring-2 ring-inset ring-white justify-center relative text-gray-400 select-none;
    }

    .container-mobile {
        @apply w-md sm:w-auto;
    }

    .btn {
        @apply inline-flex 
                items-center 
                px-4 
                py-2
                border   
                border-transparent 
                rounded-lg 
                font-semibold 
                text-xs 
                bg-black
                text-white 
                uppercase 
                tracking-widest
                focus:outline-none 
                focus:ring  
                disabled:opacity-40 
                transition ease-in-out duration-150;
    }

    .form-input {
        @apply rounded-md
                shadow-sm
                border-gray-300
                focus:border-indigo-300
                focus:ring
                focus:ring-indigo-200
                focus:ring-opacity-50
                disabled:opacity-60
                disabled:bg-gray-200;
    }

    .form-input.invalid, input[type='radio'].invalid, input[type='checkbox'].invalid {
        @apply ring-2 
        ring-red-300
        border-red-300
    }

    label.invalid {
        @apply text-red-500;
    }

    .link {
        @apply font-semibold text-blue-600 cursor-pointer no-underline hover:no-underline inline-block hover:text-blue-800;
    }

    .form-label {
        @apply block 
        font-medium 
        text-sm 
        text-gray-700 
        mb-1
    }

    .form-input {
        @apply rounded-md
        shadow-sm
        border-gray-300
        focus:border-indigo-300
        focus:ring
        focus:ring-indigo-200
        focus:ring-opacity-50
        disabled:opacity-60;
    }
    
    .dt {
        @apply font-semibold text-gray-400 mb-1 mr-2;
    }

    .label {
        @apply block font-medium text-sm text-gray-700 mb-1;
    }

    .table {
        @apply min-w-full divide-y divide-gray-300;
    }

    .td {
        @apply px-4 py-2;
    }

    .th {
        @apply p-4 text-left text-xs font-medium text-gray-300 uppercase tracking-wider;
    }

    /*** Loading Icon Spinner ***/
    .spinner {
        animation: rotate 2s linear infinite;
        z-index: 2;
        /*position: absolute;*/
        /*top: 50%;*/
        /*left: 50%;*/
        /*margin: -25px 0 0 -25px;*/
        width: 50px;
        height: 50px;
    }

    .spinner .path {
        stroke: currentColor;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
  /*** END Loading Icon Spinner ***/

    .drag-drop-container.drag-over{
        @apply border-green-400
        bg-green-200
        text-green-600;
    }
}